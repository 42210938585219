import * as tokens from '@juntossomosmais/atomium-tokens'

const orange1 = tokens.colorBrandPrimaryRegular
const blue2 = tokens.colorBrandSecondaryRegular
const green1 = tokens.colorContextualSuccessRegular
const red2 = tokens.colorContextualErrorRegular
const red3 = tokens.colorContextualErrorDark1
const yellowDark3 = tokens.colorContextualWarningDark1
const grayDark = tokens.colorNeutralLight4
const neutralLigth4 = tokens.colorNeutralLight4

const theme = {
  dark: false,
  colors: {
    black: tokens.colorNeutralBlack,
    blue1: tokens.colorBrandSecondaryLight2,
    blue2,
    blue3: tokens.colorBrandSecondaryRegular,
    blue4: tokens.colorBrandSecondaryDark1,
    danger: red2,
    gray1: tokens.colorNeutralLight5,
    gray2: tokens.colorNeutralLight4,
    gray3: tokens.colorNeutralLight2,
    gray4: tokens.colorNeutralLight1,
    gray5: tokens.colorNeutralRegular,
    gray6: tokens.colorNeutralLight1,
    gray7: tokens.colorNeutralLight5,
    gray8: tokens.colorNeutralLight1,
    green1,
    green2: tokens.colorContextualSuccessRegular,
    green3: tokens.colorContextualSuccessDark1,
    green4: tokens.colorContextualSuccessLight1,
    green5: tokens.colorContextualSuccessLight2,
    orange1,
    orange2: tokens.colorBrandPrimaryRegular,
    red1: tokens.colorContextualErrorDark1,
    red2,
    red3: red3,
    red4: tokens.colorContextualErrorRegular,
    red5: tokens.colorContextualErrorLight2,
    yellowDark3,
    grayDark,
    primary: orange1,
    secondary: blue2,
    success: tokens.colorContextualSuccessRegular,
    warning: yellowDark3,
    error: tokens.colorContextualErrorRegular,
    info: grayDark,
    colorContextualInfoDark1: tokens.colorContextualInfoDark1,
    colorContextualInfoRegular: tokens.colorContextualInfoRegular,
    colorContextualInfoLight1: tokens.colorContextualInfoLight1,
    colorContextualInfoLight2: tokens.colorContextualInfoLight2,
    white: tokens.colorNeutralWhite,
    neutralLigth4,
  },
  spaces: {
    space: tokens.spacingLarge,
    md: tokens.spacingXxlarge,
  },
}

export default theme
