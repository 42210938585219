import type { RouteLocationNormalized } from 'vue-router'

import { handleRedirects } from '../helpers/location'

export default defineNuxtRouteMiddleware((to: RouteLocationNormalized) => {
  if (!process.server) {
    handleRedirects()

    // In a real app you would probably not redirect every route to `/`
    // however it is important to check `to.path` before redirecting or you
    // might get an infinite redirect loop
    if (to.path === '/') {
      return navigateTo('/home')
    }
  }
})
