import * as Sentry from '@sentry/vue'
import { defineNuxtPlugin } from 'nuxt/app'

import { envs } from '@marketplace/utils/get-envs'

const isProd = envs.VUE_APP_ENV === 'production'

export default defineNuxtPlugin({
  name: 'sentry',
  parallel: true,
  async setup(nuxtApp) {
    const vueApp = nuxtApp.vueApp

    Sentry.init({
      app: vueApp,
      dsn: envs.VUE_APP_SENTRY_DSN_MODULE,
      enableTracing: true,
      environment: envs.VUE_APP_ENV,
      logErrors: true,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: envs.VUE_APP_ENV === 'production' ? 0.01 : 0,
      trackComponents: true,
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: isProd ? 1 : 0,
    })
  },
})
