<template>
  <svg
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 521.582 113.321"
  >
    <path
      :fill="$parent.$attrs['fill'] || '#3b3535'"
      d="M250.019.813h12.595v37.922h19.502v9.345H250.02zM309.611 39.547c7.652 0 10.7-7.313 10.7-15.1 0-8.195-3.048-15.102-10.7-15.102-7.652 0-10.699 6.907-10.699 15.101 0 7.788 3.047 15.101 10.7 15.101m0-39.547c15.574 0 23.7 9.277 23.7 24.446 0 15.033-8.26 24.446-23.7 24.446S285.91 39.48 285.91 24.446C285.91 9.277 294.036 0 309.611 0M336.904 37.177c1.557.745 3.52 1.151 5.688 1.151 3.725 0 6.433-2.64 6.433-10.36V.812h12.595V31.76c0 10.157-4.13 17.132-16.929 17.132-2.776 0-5.62-.745-7.787-1.286zM392.23 10.564h-.136l-6.095 18.487h11.85zM384.51.813h15.44l18.215 47.266H404.35l-3.52-10.09h-17.743l-3.724 10.09h-12.799zM276.166 112.509h-8.465l-17.81-47.267h7.585l14.56 41.307h.135l14.965-41.307h7.11zM300.68 65.242h6.772v47.267h-6.771zM325.873 85.557h5.756c6.433 0 10.225-2.844 10.225-7.381 0-4.266-3.25-6.975-9.48-6.975h-6.501ZM319.1 65.242h11.58c9.345 0 18.35 1.49 18.35 12.799 0 5.756-4.265 9.818-9.75 10.767v.135c2.1.813 3.115 1.828 4.605 4.74l8.938 18.826h-7.72L337.86 96.12c-2.1-4.604-4.334-4.604-7.923-4.604h-4.063v20.992H319.1zM369.078 71.201h-14.694v-5.96h36.161v5.96H375.85v41.308h-6.772ZM434.089 92.938c0 13.273-6.704 20.383-18.352 20.383-11.647 0-18.351-7.11-18.351-20.383V65.242h6.772v26.816c0 9.075 2.844 15.304 11.58 15.304 8.735 0 11.579-6.23 11.579-15.304V65.242h6.772ZM464.495 71.607l-9.413 23.43h18.826Zm-3.318-6.365h7.11l19.977 47.267h-7.381l-4.808-11.919h-23.16l-4.808 11.919h-7.11zM495.036 65.242h6.772v41.308h19.774v5.959h-26.546Z"
    />
    <path
      id="part-red"
      class="logo__part"
      fill="#ab2723"
      d="M62.494 57.145a6.726 6.726 0 1 0 0 13.452 6.726 6.726 0 0 0 0-13.452M85.663 41.78a8.326 8.326 0 0 0-5.961-2.522H61.617c-4.594 0-6.545 3.295-4.333 7.323L70.32 56.708l10.41 13.321c4.029 2.212 7.324.262 7.324-4.335l.002-18.08a8.325 8.325 0 0 0-2.394-5.834"
    />
    <path
      id="part-green"
      class="logo__part"
      fill="#728428"
      d="M119.977 14.753a6.727 6.727 0 0 0 0-13.453 6.726 6.726 0 0 0-6.725 6.727 6.725 6.725 0 0 0 6.725 6.726M112.042 15.34 101.74 2.129c-4.028-2.213-7.323-.26-7.323 4.334v18.083c0 2.345.983 4.469 2.552 5.99a8.32 8.32 0 0 0 5.803 2.365l18.088.001c4.595 0 6.545-3.295 4.332-7.323z"
    />
    <path
      id="part-orange"
      class="logo__part"
      fill="#ed7004"
      d="M119.818 57.145a6.726 6.726 0 1 0 0 13.452 6.726 6.726 0 0 0 0-13.452M121.456 39.258h-18.078a8.326 8.326 0 0 0-6.028 2.595 8.32 8.32 0 0 0-2.327 5.76v18.081c0 4.595 3.295 6.547 7.324 4.335l10.074-12.987 13.369-10.461c2.212-4.028.262-7.323-4.334-7.323"
    />
    <path
      id="part-blue"
      class="logo__part"
      fill="#025f9f"
      d="M63.261 14.753a6.726 6.726 0 1 0 0-13.451 6.726 6.726 0 0 0 0 13.45M88.055 6.462c0-4.597-3.295-6.547-7.322-4.334L70.053 15.72l-12.768 9.859c-2.212 4.03-.262 7.326 4.334 7.323H79.7a8.318 8.318 0 0 0 5.687-2.26c1.636-1.528 2.669-3.694 2.669-6.096z"
    />
    <path
      :fill="$parent.$attrs['fill'] || '#3b3535'"
      d="M37.169 14.222H5.429C2.443 14.222 0 16.666 0 19.652c0 2.986 2.443 5.429 5.43 5.429h31.739c2.986 0 5.43-2.443 5.43-5.43 0-2.985-2.444-5.429-5.43-5.429M37.169 32.598H15.452c-2.986 0-5.429 2.443-5.429 5.43 0 2.985 2.443 5.429 5.43 5.429h21.716c2.986 0 5.43-2.444 5.43-5.43 0-2.986-2.444-5.429-5.43-5.429M37.169 50.139H23.805c-2.986 0-5.43 2.443-5.43 5.429s2.444 5.429 5.43 5.429H37.17c2.986 0 5.43-2.443 5.43-5.43 0-2.985-2.444-5.428-5.43-5.428M179.837 102.391a5.43 5.43 0 1 1 0-10.858 5.43 5.43 0 0 1 0 10.858m0-20.046c-8.073 0-14.617 6.544-14.617 14.617s6.544 14.617 14.617 14.617c8.072 0 14.617-6.544 14.617-14.617s-6.545-14.617-14.617-14.617"
    />
    <path
      :fill="$parent.$attrs['fill'] || '#3b3535'"
      d="M199.541 53.488a2.608 2.608 0 0 1-2.608 2.608H156.78a2.608 2.608 0 0 1-2.608-2.608V14.724a2.607 2.607 0 0 1 2.608-2.607h21.266c.787 0 1.532.355 2.027.967l18.889 23.346c.375.465.58 1.043.58 1.64Zm15.698-14.391L184.986 1.703a4.176 4.176 0 0 0-3.246-1.55h-34.063a4.176 4.176 0 0 0-4.176 4.177v76.842H97.779c5.495 2.866 9.325 8.488 9.646 15.035h54.473c.396-9.567 8.274-17.203 17.939-17.203 9.664 0 17.542 7.636 17.939 17.203h15.091a3.311 3.311 0 0 0 3.302-3.302V41.723c0-.955-.328-1.883-.93-2.626M81.2 81.172H50.68a3.31 3.31 0 0 0-3.302 3.301v8.433a3.311 3.311 0 0 0 3.302 3.301h20.874c.321-6.547 4.15-12.17 9.646-15.035"
    />
    <path
      :fill="$parent.$attrs['fill'] || '#3b3535'"
      d="M89.49 102.53a5.43 5.43 0 1 1 0-10.858 5.43 5.43 0 0 1 0 10.859m0-20.046c-8.073 0-14.618 6.544-14.618 14.616 0 8.073 6.545 14.618 14.617 14.618 8.073 0 14.617-6.545 14.617-14.618 0-8.072-6.544-14.616-14.617-14.616"
    />
  </svg>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LogoIcon',
})
</script>
<style src="./LogoIcon.scss" lang="scss" scoped />
