import 'vuetify/styles'

import { h } from 'vue'

import { defineNuxtPlugin } from 'nuxt/app'
import { createVuetify, type IconProps, type IconSet } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'
import { pt } from 'vuetify/locale'

import LogoIcon from '@marketplace/shared/ds/src/components/animations/LogoIcon/LogoIcon.vue'
import { vuetifyBreakpoints } from '@marketplace/shared/ds/src/plugins/vuetify/breakpoints'
import { default as vuetifyConfig } from '@marketplace/shared/ds/src/plugins/vuetify/config'

const customIconsNames = {
  LogoIcon,
}

const customIcons: IconSet = {
  component: (props: IconProps) =>
    h(props.tag, [
      h(customIconsNames[props.icon as string], { class: 'v-icon__svg' }),
    ]),
}

const vuetify = createVuetify({
  locale: {
    locale: 'pt',
    fallback: 'pt',
    messages: { pt },
  },
  display: vuetifyBreakpoints,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
      custom: customIcons,
    },
  },
  theme: {
    defaultTheme: 'myCustomTheme',
    themes: {
      myCustomTheme: vuetifyConfig.light,
    },
  },
})

export default defineNuxtPlugin((app) => {
  app.vueApp.use(vuetify)
})
