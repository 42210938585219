import { createGtm, useGtm } from '@gtm-support/vue-gtm'
import { defineNuxtPlugin } from 'nuxt/app'

import { envs } from '@marketplace/utils/get-envs'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(
    createGtm({
      id: envs.VUE_APP_GOOGLE_TAG_MANAGER_TOKEN,
      defer: false,
      compatibility: false,
      enabled: true,
      debug: true,
      loadScript: true,
      vueRouter: useRouter(),
    })
  )

  nuxtApp.vueApp.config.globalProperties.$ga = {
    event: async (event, dataParams) => {
      useGtm().trackEvent({
        event,
        ...dataParams,
        send_to: envs.VUE_APP_GOOGLE_ANALYTICS_4_TOKEN,
      })
    },
  }
})
