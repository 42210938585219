import { storageKeys } from '@marketplace/utils/enums/storage-keys'
import { envs } from '@marketplace/utils/get-envs'

enum QUERY_PARAMETER {
  EMAIL = 'e',
}

export const locationHasQueryParams = (param?: string) => {
  const url = new URL(window.location.href)

  return param ? url.searchParams.has(param) : window.location.search?.length
}

export const storageHasToken = () => {
  return (
    window.localStorage.getItem(storageKeys.TOKEN_ID)?.length ||
    window.localStorage.getItem(storageKeys.TOKEN_JUNTOS_ID)?.length
  )
}

export const redirectToCadastro = () => {
  window.location.href =
    window.location.origin + '/l/cadastro' + window.location.search
}

export const redirectToLogged = (routeSufix?: string) => {
  window.location.href = `${envs.VUE_APP_JSM_WEB_CUSTOMER}/${routeSufix ?? ''}`
}

export const handleRedirects = () => {
  if (locationHasQueryParams(QUERY_PARAMETER.EMAIL)) return redirectToCadastro()

  if (locationHasQueryParams()) return redirectToLogged(window.location.search)

  if (storageHasToken()) redirectToLogged()
}
