import { envs } from '../get-envs'

export const storageKeys = {
  TEMPORARY_TOKEN: 'JSMTMPID',
  TEMPORARY_SESSION: 'JSMTMPSID',
  PARTNER_SELECTED: 'JSMPARTNERSELECTED',
  PARTNERS_DETAILS: 'JSMPARTNERSDETAILS',
  IS_GRID: 'JSMISGRIDVIEW',
  TOKEN_ID: 'JSMTID',
  TOKEN_JUNTOS_ID: 'JIDT',
  GERDAU_ID: envs.VUE_APP_GERDAU_ID || '',
  VOTORANTIM_ID: envs.VUE_APP_VOTORANTIM_ID || '',
  TIGRE_ID: envs.VUE_APP_TIGRE_ID || '',
  NAIRAPACK_ID: envs.VUE_APP_NAIRAPACK_ID || '',
  PREMIUM_ID: envs.VUE_APP_PREMIUM_ID || '',
  AUTOCOMPLETE_RECENT_SEARCHES: 'JSMRECENTSEARCH',
  ALGOLIA_API_KEY: 'JSMALGOLIAAPIKEY',
  ONBOARDING_SUGGETED_LIST: 'JSMONBOARDINGSUGGESTEDLIST',
  HIDE_SALESMAN_CACHE_ALERT_TILL: 'JSMSHOWSALESMANCACHEALERTTILL',
  UNLEASH: 'unleash:repository:repo',
  FIRST_ROUTE: 'FIRST_ROUTE',
}
