import revive_payload_client_YTGlmQWWT5 from "/home/vsts/work/1/s/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/home/vsts/work/1/s/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/home/vsts/work/1/s/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/home/vsts/work/1/s/node_modules/nuxt/dist/app/plugins/payload.client.js";
import components_plugin_KR1HBZs4kY from "/home/vsts/work/1/s/apps/access/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/home/vsts/work/1/s/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/home/vsts/work/1/s/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/home/vsts/work/1/s/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import aos_client_rDSYU13oOX from "/home/vsts/work/1/s/apps/access/plugins/aos.client.ts";
import atomium_client_w7w4cyd8fL from "/home/vsts/work/1/s/apps/access/plugins/atomium.client.ts";
import ga4_p7brr6xl3M from "/home/vsts/work/1/s/apps/access/plugins/ga4.ts";
import sentry_client_shVUlIjFLk from "/home/vsts/work/1/s/apps/access/plugins/sentry.client.ts";
import vue_gtm_yUJQMSAo2j from "/home/vsts/work/1/s/apps/access/plugins/vue-gtm.ts";
import vueLazyLoad_uspJSMIzFf from "/home/vsts/work/1/s/apps/access/plugins/vueLazyLoad.ts";
import vueSecureHTML_client_Wu1NAWrKFC from "/home/vsts/work/1/s/apps/access/plugins/vueSecureHTML.client.ts";
import vuetify_7h9QAQEssH from "/home/vsts/work/1/s/apps/access/plugins/vuetify.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  chunk_reload_client_SeG0EjL5Ec,
  check_outdated_build_client_LIYcCMJD18,
  aos_client_rDSYU13oOX,
  atomium_client_w7w4cyd8fL,
  ga4_p7brr6xl3M,
  sentry_client_shVUlIjFLk,
  vue_gtm_yUJQMSAo2j,
  vueLazyLoad_uspJSMIzFf,
  vueSecureHTML_client_Wu1NAWrKFC,
  vuetify_7h9QAQEssH
]