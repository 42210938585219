import { ThemeDefinition } from 'vuetify'

import theme from '../../styles/theme'

const light: ThemeDefinition = theme

const vuetifyTheme = {
  defaultTheme: 'light',
  themes: light,
}

export default { vuetifyTheme, theme, light }
