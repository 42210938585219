/*
This file is generated in build time, so your changes will not impact it.

- Wanna make changes at envs? Update the .env file;
- Wanna make changes to how this file is generated? Update the load-envs executor.
*/

const envs = JSON.parse(`{
  "VUE_APP_ENV": "production",
  "VUE_APP_CHAORDIC_API_KEY": "juntossomosmais",
  "VUE_APP_APPLICATION_INSIGHTS_ID": "8d6dcf14-0943-4600-a7f9-455621fa7765",
  "VUE_APP_GETNET_SELLER_ID": "5af34030-1891-4cee-8a18-b7b6c93686a7",
  "VUE_APP_SENTRY_TOKEN": "dc3dcecfaaf84de79a1ac4fb9c834065feb2d3ff0f5f469fbca524774b842f67",
  "VUE_APP_GOOGLE_TAG_MANAGER_TOKEN": "GTM-PGRSV4S",
  "VUE_APP_GOOGLE_ANALYTICS_TOKEN": "UA-131777394-2",
  "VUE_APP_RECAPTCHA_V3_API_KEY": "6Ld8pAIbAAAAAHOZQcQjiHDIh8xg_a-2Cc5InECV",
  "VUE_APP_RECAPCHA_SITE_KEY": "6Ld_mYcUAAAAANOr5q_R2oJUA692ovebKjj-33tF",
  "VUE_APP_GOOGLE_ANALYTICS_4_TOKEN": "G-64JT9X8QQH",
  "VUE_APP_GOOGLE_ANALYTICS_4_API_SECRET": "GdO7av5LRSaSiXm_Q21reA",
  "VUE_APP_TEST_MAIN_URL": "https://loja.juntossomosmais.com.br",
  "VUE_APP_JSM_WEB_CUSTOMER": "https://loja.juntossomosmais.com.br/l",
  "VUE_APP_CDN_WEB_CUSTOMER_ENTRY_POINT": "https://cdn-marketplace.juntossomosmais.com.br/fe-web-customer/l/",
  "VUE_APP_CDN_ACCESS_ENTRY_POINT": "https://cdn-marketplace.juntossomosmais.com.br/fe-web-customer",
  "VUE_APP_AUTH0_FEATURE_FLAG": "TO_DISABLE_IT_JUST_COMMENT_THIS_LINE",
  "VUE_APP_JID_MY_ACCOUNT_REDIRECT": "https://minhaconta.juntossomosmais.com.br",
  "VUE_APP_API_BASE_URL": "https://api-loja.juntossomosmais.com.br",
  "VUE_APP_API_BASE_URL_SITES_API": "https://api-sites-loja.juntossomosmais.com.br",
  "VUE_APP_VOTORANTIM_ID": "d0164e85-8bfc-4138-37e3-08d68bda781d",
  "VUE_APP_GERDAU_ID": "c60751aa-49ea-4ff3-9583-2adeabcd4450",
  "VUE_APP_TIGRE_ID": "67ef8302-b546-445a-9ab2-8f7f88b62078",
  "VUE_APP_NAIRAPACK_ID": "268f5e9f-d561-4dd0-b454-576ca57b3f60",
  "VUE_APP_PREMIUM_ID": "f92d49e4-8589-4b00-9da3-d2fb0471d6e6",
  "VUE_APP_ZENDESK_TOKEN": "23433bbe-f769-49a9-b1fe-6b944185f9d2",
  "VUE_APP_SENTRY_DSN": "https://a929762bcc4d46c8a0e94ef7272ec9b4@o326301.ingest.sentry.io/5522095",
  "VUE_APP_SENTRY_DSN_MODULE": "https://f71b67646876623d88a3f4b60966dbf7@o326301.ingest.us.sentry.io/5522095",
  "VUE_APP_GETNET_CHECKOUT_SCRIPT": "https://checkout.getnet.com.br/loader.js",
  "VUE_APP_ORACLE_RESPONSYS_APP_SERVICE_KEY": "BKjmdFxaX4unzvArwULKxRMX-suOhelyMtBnq-twxZdAhiNHiwy1uPVuBMeWGrQ6QXYdhqThfR6jagPHAkpvD7k=",
  "VUE_APP_ORACLE_RESPONSYS_API_KEY": "ABE-VlF1GhtlbUeo4qYXiuqgg",
  "VUE_APP_ORACLE_RESPONSYS_ACCOUNT_TOKEN": "ABErUIF5Pmlgl2ua105kDHs9A",
  "VUE_APP_ORACLE_RESPONSYS_APP_VER": "0.0.0",
  "VUE_APP_ORACLE_RESPONSYS_API_HOST": "https://api.pushio.com",
  "VUE_APP_ORACLE_RESPONSYS_LAZY": "true",
  "VUE_APP_UNLEASH_TOKEN": "*:production.e010b1f7a0265eb5ee61c1995971b788b7ff0016137515d38f03a39f",
  "VUE_APP_UNLEASH_URL": "https://api.juntossomosmais.com.br/mithril/toggle/frontend",
  "VUE_APP_UNLEASH_CDN_URL": "https://assets-tools.juntossomosmais.com.br/unleash/prd",
  "VUE_APP_UNLEASH_APP_NAME": "web-customer-typescript",
  "VUE_APP_COLOSSEUM_URL": "https://www.juntossomosmais.com.br",
  "VUE_APP_COLOSSEUM_LOGIN": "https://www.juntossomosmais.com.br/auth/login",
  "VUE_APP_COLOSSEUM_USER_HOW_WORKS": "https://www.juntossomosmais.com.br/user/o-programa/como-funciona",
  "VUE_APP_GETNET_TOKEN": "k8vif92e",
  "VUE_APP_EXTRATO_VLOZ": "https://extratovloz.vcimentos.com",
  "NODE_ENV": "production"
}`)
export {envs}
