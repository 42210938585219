import { defineNuxtPlugin } from 'nuxt/app'
import { query } from 'vue-gtag'

import { envs } from '@marketplace/utils/get-envs'

export interface IGaClick extends HTMLElement {
  __GaClickHandler__(event: Event): void
}

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('ga4Click', {
    beforeMount(el, binding) {
      (el as IGaClick).__GaClickHandler__ = () => {
        query('event', binding.value.eventName, {
          ...binding.value.params,
          send_to: envs.VUE_APP_GOOGLE_ANALYTICS_4_TOKEN,
        })
      }
      el.addEventListener('click', (el as IGaClick).__GaClickHandler__)
    },
    unmounted(el) {
      document.body.removeEventListener(
        'click',
        (el as IGaClick).__GaClickHandler__
      )
    },
  })
})
