import { default as indexEwAHI7vyk1Meta } from "/home/vsts/work/1/s/apps/access/pages/home/index.vue?macro=true";
import { default as indexDETYg3h6eAMeta } from "/home/vsts/work/1/s/apps/access/pages/index.vue?macro=true";
import { default as indexRYne5QxKMFMeta } from "/home/vsts/work/1/s/apps/access/pages/sobre/index.vue?macro=true";
export default [
  {
    name: indexEwAHI7vyk1Meta?.name ?? "home",
    path: indexEwAHI7vyk1Meta?.path ?? "/home",
    meta: indexEwAHI7vyk1Meta || {},
    alias: indexEwAHI7vyk1Meta?.alias || [],
    redirect: indexEwAHI7vyk1Meta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/apps/access/pages/home/index.vue").then(m => m.default || m)
  },
  {
    name: indexDETYg3h6eAMeta?.name ?? "index",
    path: indexDETYg3h6eAMeta?.path ?? "/",
    meta: indexDETYg3h6eAMeta || {},
    alias: indexDETYg3h6eAMeta?.alias || [],
    redirect: indexDETYg3h6eAMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/apps/access/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexRYne5QxKMFMeta?.name ?? "sobre",
    path: indexRYne5QxKMFMeta?.path ?? "/sobre",
    meta: indexRYne5QxKMFMeta || {},
    alias: indexRYne5QxKMFMeta?.alias || [],
    redirect: indexRYne5QxKMFMeta?.redirect || undefined,
    component: () => import("/home/vsts/work/1/s/apps/access/pages/sobre/index.vue").then(m => m.default || m)
  }
]